import React from 'react';

import holidayIcon from '../../../../images/holiday-sale/holiday_sale.svg';
import './HolidaySaleGreenBanner.css';

const HolidaySaleGreenBanner = () => {
  return (
    <a
      className='link-wrapper'
      href='https://dna.sequencing.com/shop-all-bundles/?utm_source=Website&utm_medium=Top-Banner&utm_campaign=Holiday2024&utm_content=Holiday-Banner-GreenSetA'
    >
      <div className='banner-wrapper'>
        <div className='icon-wrapper'>
          <img alt='Lowest price ever' src={holidayIcon} />
        </div>
        <div className='cta-wrapper'>
          <div className='cta-text'>
            <p>
              HOLIDAY SUPER SALE!
            </p>
            <span className='price'>Up To{" "}<b>87% Off</b>{" "}+ Free Shipping</span>
          </div>
          <div className='cta-btn'>
            Save Big Now
          </div>
        </div>
      </div>
    </a>
  )
}

export default HolidaySaleGreenBanner;
